import React from "react";
import { Link } from "gatsby"
import styles from "./styles-cppa.module.scss";

export default function PrivacyPolicy() {
  return (
    <section className={styles.privacy}>
      <div className={styles.privacy__container}>
        <h1 className={styles.privacy__title}>Sync.AI Privacy Policy for California Residents</h1>
        <div className={styles.privacy__main}>
          <p className={styles.c1} style={{textAlign: 'center'}}>
            <span>Last Revised: November 25, 2020</span>
          </p>
          <p className={`${styles.c1} ${styles.bold} ${styles.c2}`}>1. INTRODUCTION</p>
          <p className={styles.c1}>
            If you are a California resident, the information below may apply to you in addition to our Privacy Policy [
            <Link href={'/privacy-policy'}>
              https://sync.ai/privacy-policy
            </Link>
            ].  This notice (“<span className={styles.bold}>Privacy Notice</span>”) uses certain terms that have the
            meanings given to them by California Consumer Privacy Act of 2018 and the California Consumer Privacy
            Act Regulation by the Attorney General (collectively, “<span className={styles.bold}>CCPA</span>”). Any
            terms defined in the CCPA have the same meaning when used in this Privacy Notice.
          </p>
          <p className={styles.c1}>
            If you have a visual disability, you may be able to use a screen reader or other text-to-speech or
            text-to-Braille tool to review the contents of this Privacy Notice.
          </p>
          <p className={`${styles.c1} ${styles.bold} ${styles.c2}`}>2. WHAT INFORMATION DO WE COLLECT?</p>
          <p className={styles.c1}>
            We collect certain information which identifies an individual or may with reasonable effort identify an individual (“<span className={styles.bold}>Personal Information</span>”). In addition, we treat
            information which is specifically connected or linked to any Personal Information - as long as such
            connection or linkage exists - as Personal Information.
          </p>
          <p className={styles.c1}>
            We have collected the following categories of Personal Information in the last 12 months:
          </p>
          <ul className={styles.noBullets}>
            <li>
              <p className={`${styles.c1} ${styles.bold}`}>
                a. Category and business/commercial purpose of collection of Personal Information
              </p>
              
              <ul className={`${styles.disc} ${styles.nested}`}>
                <li>
                  Please check the information disclosed in section 1 (What information we collect, why we collect
                  it, and how it is used?) of our general privacy policy here [
                  <Link href={'/privacy-policy'}>
                    https://sync.ai/privacy-policy
                  </Link>
                  ]
                </li>
              </ul>
              
            </li>
            <li>
              <p className={`${styles.c1} ${styles.bold}`}>
                b. Categories of sources from which we collect your Personal Information:
              </p>
              <ul className={`${styles.disc} ${styles.nested}`}>
                <li>
                  <p className={styles.c1}>
                    Directly from consumers upon registration or use of the Service. Directly or indirectly from
                    activity on our Service by cookies or other tracking technologies. From third parties that
                    interact with us, including social networks, other consumers who use our Service and other
                    publicly available sources.
                  </p>
                </li>
                <li>
                  <p className={styles.c1}>
                    Directly from consumer's device by using cookies or other tracking technologies.
                  </p>
                </li>
                <li>
                  <p className={styles.c1}>
                    Directly from consumer's device using Wi-Fi or cellular technology in your device, or by using
                    IP address, or as directly submitted from the consumer, or as made available on publicly
                    available sources
                  </p>
                </li>
              </ul>
            </li>
            <li>
              <p className={`${styles.c1} ${styles.bold}`}>
                c. Shared your Personal Information with the following categories of third parties:
              </p>
              <ul className={`${styles.disc} ${styles.nested}`}>
                <li>
                  <p className={styles.c1}>
                    We disclose your Personal Information to third parties for a business purpose. When we disclose
                    Personal Information for a business purpose, we enter into a contract that describes the purpose
                    and requires both parties to confidential and not use it for any purpose except in the
                    performance of the contract.
                  </p>
                </li>
                <li>
                  <p className={styles.c1}>
                    In the preceding twelve (12) months, we have disclosed the above-mentioned categories of
                    Personal Information with the following categories of recipients for a business purpose:
                  </p>
                  <ul className={styles.roman}>
                    <li>
                      <p className={styles.c1}>Our business partners;</p>
                    </li>
                    <li>
                      <p className={styles.c1}>Our affiliates;</p>
                    </li>
                    <li>
                      <p className={styles.c1}>Service providers;</p>
                    </li>
                    <li>
                      <p className={styles.c1}>Social media networks;</p>
                    </li>
                    <li>
                      <p className={styles.c1}>Third-party marketing partners;</p>
                    </li>
                    <li>
                      <p className={styles.c1}>
                        Regulators, courts or competent authorities, to the extent necessary, to comply with
                        applicable laws, regulations and rules (including, without limitation, federal, state or
                        local laws), and requests of law enforcement, regulatory and other governmental agencies or
                        if required to do so by court order;
                      </p>
                      
                    </li>
                    <li>
                      <p className={styles.c1}>
                        We may share or transfer Personal Information to third parties as assets that are part of a
                        merger, acquisition, bankruptcy, or other transaction in which the third party assumes
                        control of all or part of the Company. Such transfer will be handled according to the
                        requirement of the CCPA and shall not be regarded as a sale of Personal Information (as it
                        is defined in the CCPA).
                      </p>
                    </li>
                  </ul>
                  
                </li>
              </ul>
              <p className={`${styles.c1} ${styles.bold}`}>d. Sale of data</p>
              
              <ul className={`${styles.disc} ${styles.nested}`}>
                <li>
                  <p className={styles.c1}>
                    We may sell (according to the CCPA definition of a "sale") your Personal Information to third
                    parties and businesses for commercial purposes. You have the right to opt-out of a sale of
                    Personal Information (see below for more information on how to exercise your rights). We sold
                    the following categories of personal information:
                  </p>
                  
                  <ul className={styles.roman}>
                    <li>
                      <p className={styles.c1}>
                        Identifiers (online) associated with a device used to interact with our digital properties
                        or advertisements (such as a device identifier; cookies, beacons, pixel tags, mobile ad
                        identifiers and similar technology);
                      </p>
                      
                    </li>
                    <li>
                      <p className={styles.c1}>
                        Internet and other electronic network activity information associated with a device used to
                        interact with Digital Properties or advertisements We do not sell the personal information
                        of consumers under the age of 16 if we have actual knowledge of the individual’s age.
                      </p>
                    </li>
                  </ul>
                </li>
              </ul>
              
              <p className={styles.c1}>
                We do not sell personal information as the term is traditionally understood, versus the definition
                under the CCPA — for example, we do not exchange for money personal information to data brokers or
                third parties for their marketing purposes independent of us.
              </p>
              
            </li>
          </ul>
          <p className={styles.c1}>
            We will not collect additional categories of personal information or use the personal information we
            collected for materially different, unrelated, or incompatible purposes without providing you notice.
          </p>
          
          <p className={`${styles.c1} ${styles.bold} ${styles.c2}`}>3. YOUR RIGHTS UNDER THE CCPA</p>
          
          <ul className={styles.noBullets}>
            <li>
              <p className={`${styles.c1} ${styles.bold}`}>
                3.1 The CCPA provides consumers with specific rights regarding their Personal Information. This
                section describes your CCPA rights (which may be subject to certain exemptions or derogations) and
                explains how to exercise those rights.
              </p>
              
              <ul className={`${styles.disc} ${styles.nested}`}>
                <li>
                  <p className={styles.c1}>
                    You have the right to know what Personal Information is being collected about you, this include
                    the right to request that we disclose what Personal Information of yours we collect, use,
                    disclose, and sell;
                  </p>
                  
                </li>
                <li>
                  <p className={styles.c1}>
                    You have the right to request the erasure/deletion of your Personal Information (e.g. from our
                    records and the records of our service providers). Please note that there may be circumstances
                    in which we are required to retain your Personal Information, for example for the establishment,
                    exercise or defense of legal claims;
                  </p>
                  
                </li>
                <li>
                  <p className={styles.c1}>
                    You have the right to know whether your Personal Information is sold or disclosed and to whom;
                  </p>
                  
                </li>
                <li>
                  <p className={styles.c1}>
                    You have the right to the restriction of, or to object to, the processing of your Personal
                    Information, including the right to opt in or opt out of sale of your Personal Information to
                    third parties, if applicable, where such requests are permitted by law;
                  </p>
                  
                </li>
                <li>
                  <p className={styles.c1}>
                    You have the right not to receive discriminatory treatment for the exercise of the privacy
                    rights conferred by the CCPA;
                  </p>
                  
                </li>
                <li>
                  <p className={styles.c1}>
                    You have a right to lodge a complaint with your local data protection supervisory authority
                    (i.e., your place of habitual residence, place or work or place of alleged infringement) at any
                    time or before the relevant institutions in your place of residence (e.g. the Attorney General
                    in your State). We ask that you please attempt to resolve any issues with us before you contact
                    your local supervisory authority and/or relevant institution. You have certain choices about
                    your Personal Information. Where you have consented to the processing of your Personal
                    Information, you may withdraw that consent at any time and prevent further processing by
                    contacting us as described in this Privacy Policy. Even if you opt out, we may still collect and
                    use non-Personal Information regarding your activities on our services and for other legal
                    purposes as described above. While we cannot guarantee privacy perfection, we will address any
                    requests to the best of our ability as soon as possible. We will process such requests in
                    accordance with applicable laws. You have a right to opt-out of the Sale of Personal Information
                  </p>
                  
                </li>
              </ul>
            </li>
            <li>
              <p className={`${styles.c1} ${styles.bold} ${styles.c2}`}>3.2 Exercising Your Rights</p>
              
              <ul className={styles.disc}>
                <li>
                  <p className={styles.c1}>
                    You can exercise your rights by submitting a verifiable consumer request to our email address:{' '}
                    <a href={'mailto:support@sync.ai'} target={'_blank'}>
                      support@sync.ai
                    </a>
                    . You may use an authorized agent to submit a request on your behalf if you provide the
                    authorized agent written permission signed by you. To protect your privacy, we may take steps to
                    verify your identity before fulfilling your request. Subject to legal and other permissible
                    considerations, we will make every reasonable effort to honor your request promptly in
                    accordance with applicable law, or we will inform you if we require further information in order
                    to fulfil your request. When processing your request, we may ask you for additional information
                    to confirm or verify your identity and for security purposes, before processing and/or honoring
                    your request. We reserve the right to charge a fee where permitted by law, for instance if your
                    request is manifestly unfounded or excessive. In the event that your request would adversely
                    affect the rights and freedoms of others (for example, would impact the duty of confidentiality
                    we owe to others) or if we are legally entitled to deal with your request in a different way
                    than initial requested, we will address your request to the maximum extent possible, all in
                    accordance with applicable law.
                  </p>
                  
                </li>
                <li>
                  <p className={styles.c1}>
                    <span className={styles.bold}>Marketing emails – opt-out</span>: You may choose not to receive
                    marketing email of this type by sending a single email with the subject "BLOCK" to{' '}
                    <a href={'mailto:support@sync.ai'} target={'_blank'}>
                      support@sync.ai
                    </a>
                    . Please note that the email must come from the email account you wish to block.
                  </p>
                  
                </li>
              </ul>
            </li>
            <li>
              <p className={`${styles.c1} ${styles.bold}`}>3.3 Response Timing and Format</p>
              
              <ul className={styles.disc}>
                <li>
                  <p className={styles.c1}>
                    In accordance with the CCPA, our goal is to respond to a verifiable consumer request within 45
                    days of its receipt. If we require more time, we will inform you of the reason and extension
                    period in writing within the first 45 days period. We will deliver our written response, by mail
                    or electronically, at your option. Any disclosures we provide will cover only the 12-month
                    period preceding the request. If reasonably possible, we will provide your Personal Information
                    in a format that is readily useable and should allow you to transmit the information without
                    hindrance.
                  </p>
                  
                </li>
                <li>
                  <p className={styles.c1}>
                    To protect your privacy, we may take steps to verify your identity before fulfilling your
                    request. Subject to legal and other permissible considerations, we will make every reasonable
                    effort to honor your request promptly in accordance with applicable law or inform you if we
                    require further information in order to fulfil your request. When processing your request, we
                    may ask you for additional information to confirm or verify your identity and for security
                    purposes, before processing and/or honoring your request. We reserve the right to charge a fee
                    where permitted by law, for instance if your request is manifestly unfounded or excessive (if we
                    determine that the request warrants a fee, we will tell you why we made that decision and
                    provide you with a cost estimate before completing your request). In the event that your request
                    would adversely affect the rights and freedoms of others (for example, would impact the duty of
                    confidentiality we owe to others) or if we are legally entitled to deal with your request in a
                    different way than initial requested, we will address your request to the maximum extent
                    possible, all in accordance with applicable law.
                  </p>
                  
                </li>
                <li>
                  <p className={styles.c1}>
                    In case of rejection, the response we provide will explain the reasons for which we cannot
                    comply with your request.
                  </p>
                  
                </li>
                <li>
                  <p className={styles.c1}>
                    Please note that these CCPA rights are not absolute and requests are subject to any applicable
                    legal requirements, including legal and ethical reporting or document retention obligations.
                  </p>
                  
                </li>
              </ul>
            </li>
            <li>
              <p className={`${styles.c1} ${styles.bold} ${styles.c2}`}>3.4 Designating Agents</p>
              
              <ul className={styles.noBullets}>
                <li>
                  <p className={styles.c1}>
                    Only you or a person authorized to act on your behalf may make a consumer request related to
                    your Personal Information. You may use an authorized agent to submit a request on your behalf if
                    you provide the authorized agent written permission signed by you. You can designate an
                    authorized agent to make a request under the CCPA on your behalf if:
                  </p>
                  
                  <ul className={styles.disc}>
                    <li>
                      <p className={`${styles.c1} ${styles.bold}`}>
                        The authorized agent is a natural person or a business entity registered with the Secretary
                        of State of California;
                      </p>
                      
                    </li>
                    <li>
                        <p className={`${styles.c1} ${styles.bold}`}>
                        You sign a written declaration that you authorize the authorized agent to act on your
                        behalf.
                      </p>
                      
                    </li>
                    <li>
                        <p className={`${styles.c1} ${styles.bold}`}>
                        If you use an authorized agent to submit a request to exercise your right to know or your
                        right to request deletion, please mail a certified copy of your written declaration
                        authorizing the authorized agent to act on your behalf using the contact information below.
                      </p>
                      
                    </li>
                  </ul>
                  <p className={styles.c1}>
                    If you provide an authorized agent with power of attorney pursuant to Probate Code sections 4000
                    to 4465, it may not be necessary to perform these steps and we will respond to any request from
                    such authorized agent in accordance with the CCPA.
                  </p>
                  
                </li>
              </ul>
            </li>
          </ul>
          
          <p className={`${styles.c1} ${styles.bold} ${styles.c2}`}>4. NON-DISCRIMINATION</p>
          
          <p className={styles.c1}>
            If you decide to exercise your rights, you will not receive any discriminatory treatment by us for the
            exercise of the privacy rights conferred by the CCPA. However, note that some features of our
            Website/App, our products and/or our services will not function without your Personal Information.
            Unless permitted by the CCPA, we will not:
          </p>
          
          <ul>
            <li>
              <p className={styles.c1}>Deny you goods or services.</p>
              
            </li>
            <li>
              <p className={styles.c1}>
                Charge you different prices or rates for goods or services, including through granting discounts or
                other benefits, or imposing penalties.
              </p>
              
            </li>
            <li>
              <p className={styles.c1}>Provide you a different level or quality of goods or services.</p>
              
            </li>
            <li>
              <p className={styles.c1}>
                Suggest that you may receive a different price or rate for goods or services or a different level or
                quality of goods or services.
              </p>
            </li>
          </ul>
          
          <p className={styles.c1}>
            However, we may offer you certain financial incentives permitted by the CCPA that can result in
            different prices, rates, or quality levels. Any CCPA-permitted financial incentive we offer will
            reasonably relate to your Personal Information’s value and contain written terms that describe the
            program.
          </p>
          
          <p className={styles.c1}>
            If you provide us with the above-mentioned categories of Personal Information, our Service will enable
            you to report and block spam calls and get a live caller-id and spam detector. If you do not provide
            your Personal Information or ask us to delete or to not sell or share your Personal Information, we will
            not be able to provide you with our App service, since your Personal Information is essential to provide
            our Service to you.
          </p>
          
          <p className={`${styles.c1} ${styles.bold} ${styles.c2}`}>5. DO-NOT-TRACK SIGNALS (SHINE THE LIGHT)</p>
          
          <p className={styles.c1}>
            "Do Not Track" (<span className={styles.bold}>"DNT"</span>) mechanisms is a privacy preference that users can set in certain web browsers.
            Please note that we currently do not respond to web browser DNT signals that provide a method to opt out
            of the collection of information about online activities over time and across third-party websites or
            online services.{' '}
          </p>
          
          <p className={`${styles.c1} ${styles.bold} ${styles.c2}`}>6. UPDATES OR AMENDMENTS TO THE PRIVACY NOTICE</p>
          
          <p className={styles.c1}>
            We reserve the right to change this Privacy Notice at any time, so please re-visit this page frequently.
            If we implement significant changes to the use of your Personal Information in a manner different from
            that stated at the time of collection, we will provide notice of such changes of this Privacy Notice on
            the Service and/or we will send you an e-mail regarding such changes to the e-mail address that you
            volunteered, or by other means. To the maximum extent permitted by law, such substantial changes will
            take effect seven (7) days after such notice was provided on any of the above-mentioned methods.
            Otherwise, all other changes to this Privacy Notice are effective as of the stated “Last Revised” date,
            and your continued use of the Service after the Last Revised date will constitute acceptance of, and
            agreement to be bound by, those changes.
          </p>
          
          <p className={`${styles.c1} ${styles.bold} ${styles.c2}`}>7. HOW TO CONTACT US</p>
          
          <p className={styles.c1}>
            If you have any questions (or comments) concerning this Privacy Notice, you are welcome to contact us
            here:{' '}
            <a href={'mailto:support@sync.ai'} target={'_blank'}>
              support@sync.ai
            </a>
            .
          </p>
        </div>
      </div>
    </section>
  );
}
