import React, { useEffect } from "react"

import Layout from "../layout/layout"
import SEO from "../components/seo"

import Privacy from "../components/PrivacyPolicy/cppa"
import { AnalyticsEventCategories, emitAnalyticsEvent } from "../analytics"

const IndexPage = () => {
    useEffect(() => {
        emitAnalyticsEvent("Page view", AnalyticsEventCategories.PRIVACY_POLICY_CCPA)
    }, [])

    return (
        <Layout>
            <SEO title="Privacy Policy (CCPA)" />
            <Privacy/>
        </Layout>
    )
}

export default IndexPage
